import { Component } from 'vue-property-decorator';
import { SwiperOptions } from '@/utils/swiperUtils';

import Cutout from '@/components/CutoutComponent/CutoutComponent.vue';
import CtaItem from '@/components/CtaItem/CtaItem.vue';

import { getComponentProps } from '@/utils/vueUtils';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';
import { CtaRibbonBlockViewModel } from './CtaRibbonModule.types';
import { CtaButtonStyle } from '@/common/interfaces/CtaLinkItem';
import { mixins } from 'vue-class-component';
import SwiperMixin from '@/mixins/SwiperMixin';
import { SegmentElement } from '@/plugins/utm/SegmentElement';

@Component({
  components: {
    CtaItem,
    Cutout,
  },
  props: getComponentProps<CtaRibbonBlockViewModel>({
    Header: 'Ready for the adventure of a lifetime?',
    Items: [
      {
        Header: 'Talk to an experience specialist',
        SecondHeader: '1.800.397.3348',
        Button: {
          Title: 'Call now',
          Link: '1.800.397.3348',
          Action: 'Phone',
          ButtonStyle: CtaButtonStyle.Primary,
        },
      },
      {
        Header: 'Make a reservation',
        Button: {
          Title: 'Book',
          Link: '#link-to-rar',
          Action: 'Button',
          ButtonStyle: CtaButtonStyle.Secondary,
        },
      },
      {
        Header: 'View or order a brochure',
        Button: {
          Title: 'View brochure',
          Link: '#link-to-brochure',
          Action: 'Button',
          ButtonStyle: CtaButtonStyle.Primary,
        },
      },
    ],
  }),
})
export default class CtaRibbonModule extends mixins(SwiperMixin) {
  readonly p!: CtaRibbonBlockViewModel;
  wasInteracted = false;

  cutoutSizes: Array<ImageSizes> = [
    {
      def: new ImageSize([97, 97]),
      sizes: {
        md: new ImageSize([104, 104]),
      },
    },
    {
      def: new ImageSize([68, 68]),
      sizes: {
        xl: new ImageSize([107, 107]),
      },
    },
    {
      def: new ImageSize([68, 68]),
      sizes: {
        xl: new ImageSize([96, 96]),
      },
    },
    {
      def: new ImageSize([68, 51]),
      sizes: {},
    },
  ];

  get swiperOption(): SwiperOptions {
    return {
      pagination: {
        el: `#c-${this.uuid}-swiper-pagination`,
        clickable: true,
      },
      on: {
        slideChange: () => this.onModuleInteraction(),
        touchStart: () => this.onModuleInteraction(),
      },
    };
  }
  onModuleInteraction() {
    if (!this.wasInteracted) {
      this.wasInteracted = true;
      SegmentElement.sendEvent('moduleInteraction', {
        component_name: 'CtaItem',
        module_type_id: this.$attrs['data-segmentid'],
        module_type_name: this.$attrs.type,
        position: this.swiper.activeIndex + 1,
        interaction_type: 'carousel',
      })
    }
  }
}
