import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import ImageItem from '@/common/interfaces/ImageItem';
import { ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';

import { CUTOUTS } from '@/store/getters';

const CutoutComponentProps = Vue.extend({
  props: {
    baseClass: String,
    index: Number,
    animate: {
      type: String,
      default: 'leaves',
    },
    sizes: {
      type: Object as () => ImageSizes,
      default: {
        def: new ImageSize([100, 100]),
        sizes: {
          md: new ImageSize([200, 200]),
        },
      },
    },
  },
});

@Component({
  components: {
    ResponsiveImage,
  },
})
export default class CutoutComponent extends CutoutComponentProps {
  @Getter(CUTOUTS)
  public cutout!: (index: number) => ImageItem;

  get image() {
    return this.cutout(this.index);
  }

  get classes() {
    if (!this.baseClass) return null;

    const cutoutClass = `${this.baseClass}__cutout`;

    return [cutoutClass, `${cutoutClass}--${this.index}`];
  }
}
